import React from "react";

import {
  Box,
  ColorMode,
  Heading,
  Link,
  Paragraph,
  SEO,
  VStack,
  Wrapper,
} from "components";

const NotFoundPage = () => (
  <>
    <SEO title="Page not found | Topaz" />
    <Wrapper
      pt="layout.7"
      pb={{ _: "layout.5", md: "layout.6", lg: "layout.7" }}
      bg="brand.primary"
      pb="layout.8"
      mb="-10%"
    >
      <ColorMode mode="dark">
        <VStack space={{ _: "layout.5", md: "layout.6" }} dividers={true}>
          <Heading size={800}>404</Heading>
          <Paragraph size={600}>
            This page does not exist.{" "}
            <Box as={Link} href="/" fontWeight={600} color="brand.secondary">
              Go home
            </Box>
            .
          </Paragraph>
        </VStack>
      </ColorMode>
    </Wrapper>
  </>
);

export default NotFoundPage;
